.paragraph {
  margin: 0 auto $space-l;
  font-size: $font-size-l;
  line-height: 1.35;

  &--center { text-align: center; }
  
  a {
    display: inline-block;
    color: #f5f5f5;
    transition: color .3s;
    
    @media (hover: hover) {
      &:hover,
      &:focus { color: $color-primary; }
    }
  }
}
