.picture {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  max-width: 45rem;
  max-height: 100vh;

  @media only screen and (max-width: $max-breakpoint-xl) {
    max-height: calc(100% - 5rem);
  }

  @media only screen and (max-width: $max-breakpoint-l) {
    max-width: calc(100% - 12rem);
  }

  @media only screen and (max-width: $breakpoint-m) {
    max-width: 100%;
    max-height: calc(100% - 14rem);
  }

  @media only screen and (max-width: $breakpoint-xs) {
    max-height: calc(100% - 10rem);
  }
}
