nav {
  position: relative;
  z-index: 3;
  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
  height: 5.25rem;

  @media only screen and (max-width: $breakpoint-l) {
    height: 4rem;
  }

  &.is-shown {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0f0f0f;

    .nav-list__item {
      opacity: 1;

      &:nth-child(1) { bottom: 35vh; }
      &:nth-child(2) { bottom: 25vh; }
      &:nth-child(3) { bottom: 15vh; }
    }

    .copyright {
      display: list-item;

      a { color: #f5f5f5; }
    }
  }
}

.nav-list {
  display: flex;

  &__item {
    display: inline-block;
    margin-left: $space-huge;
    
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      margin-top: .125rem;
      background-color: #f5f5f5;
      opacity: 0;
      transition: transform .3s, opacity .3s;
    }

    @media (hover: hover) {
      &:hover::after,
      &:focus .nav-list__link::after {
        opacity: 1;
        transform: translateX($space-xs);
      }
    }
  }

  &__link {
    display: block;
    height: 100%;
    font: 300 $font-size-xl 'Oswald', sans-serif;
    letter-spacing: .25rem;
    text-decoration: none;
    text-transform: uppercase;
    color: #f5f5f5;
  }

  @media only screen and (min-width: $min-breakpoint-m) {
    &__item {
      &:nth-child(1) { animation: 600ms fade-down-right; }
      &:nth-child(2) { animation: 1200ms fade-down-right; }
      &:nth-child(3) { animation: 1800ms fade-down-right; }
    }
  }
    
  @media only screen and (max-width: $breakpoint-m) {
    &__item {
      position: fixed;
      bottom: -5rem;
      left: 50%;
      margin-left: 0;
      transform: translateX(-50%);
      transition: bottom cubic-bezier(.77, .2, .05, 1), opacity;
      opacity: 0;

      &:nth-child(1) { transition-duration: .8s; }
      &:nth-child(2) { transition-duration: 1.2s; }
      &:nth-child(3) { transition-duration: 1.6s; }
    }
  }
}

.burger {
  display: none;
  position: fixed;
  bottom: $space-m;
  left: $space-l;
  cursor: pointer;

  &__line {
    position: relative;
    z-index: 1;
    display: block;
    width: 2.5rem;
    height: .25rem;
    margin-bottom: $space-xs;
    border-radius: 3px;
    background-color: #f5f5f5;
    transform-origin: 0 0;
    transition: transform .5s cubic-bezier(.77, .2, .05, 1),
                opacity .5s cubic-bezier(.77, .2, .05, 1);
  }

  &.is-active {
    .burger__line {
      &:nth-child(1) { transform: rotate(45deg) translate($space-xxs, 0); }
      &:nth-child(2) { opacity: 0; }
      &:nth-child(3) { transform: rotate(-45deg) translate(-$space-xxs, $space-xxs); }
    }
  }

  @media only screen and (max-width: $breakpoint-m) {
    display: block;
  }
}

.copyright {
  position: fixed;
  bottom: $space-s;
  right: $space-m;

  @media only screen and (max-width: $breakpoint-m) {
    display: none;
  }

  a {
    font-size: 2.5rem;
    text-decoration: none;
    color: #0f0f0f;
  }
}
