.works {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: $space-xl 0;
}

.work {
  position: relative;
  flex-basis: calc(25% - #{$space-s});
  margin: 0 .5rem $space-s;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .75;
    transition: opacity .3s;
  }

  &:nth-child(1)::before,
  &:nth-child(1) .work__caption { background-color: #44840a; }
  &:nth-child(2)::before,
  &:nth-child(2) .work__caption { background-color: #e69c60; }
  &:nth-child(3)::before,
  &:nth-child(3) .work__caption { background-color: #20639b; }
  &:nth-child(4)::before,
  &:nth-child(4) .work__caption { background-color: #e04d1f; }
  &:nth-child(5)::before,
  &:nth-child(5) .work__caption { background-color: #249ede; }
  &:nth-child(6)::before,
  &:nth-child(6) .work__caption { background-color: #1cc7a7; }
  &:nth-child(7)::before,
  &:nth-child(7) .work__caption { background-color: #e8b333; }
  &:nth-child(8)::before,
  &:nth-child(8) .work__caption { background-color: #92176a; }
  &:nth-child(9)::before,
  &:nth-child(9) .work__caption { background-color: #687175; }

  &:hover::before,
  &:focus::before { opacity: 0; }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
  }

  &__year {
    position: absolute;
    top: $space-xxs;
    right: $space-xs;
    font-size: $font-size-xs;
    font-weight: 700;
  }

  img {
    width: 100%;
    height: 100%;
  }

  &__caption {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: $space-xs 0;
  }

  &__title,
  &__subtitle {
    text-align: center;
    margin: 0;
  }

  &__title {
    text-transform: uppercase;

    + .work__subtitle {
      margin-top: $space-xxs;
      font-weight: 400;
    }
  }

  @media only screen and (max-width: $max-breakpoint-xl) {
    flex-basis: calc(33.33% - #{$space-s});
  }

  @media only screen and (max-width: $max-breakpoint-l) {
    flex-basis: calc(33.33% - #{$space-xs});
    margin: 0 .25rem $space-xs;

    &__title { font-size: $font-size-m; }

    &__subtitle { font-size: $font-size-s; }
  }

  @media only screen and (max-width: $max-breakpoint-m) {
    flex-basis: calc(50% - #{$space-s});
    margin: 0 .5rem $space-s;

    &__title { font-size: $font-size-s; }
  }

  @media only screen and (max-width: $max-breakpoint-xs) {
    flex-basis: 100%;
    max-width: 26rem;
    margin: 0 auto $space-s;

    &__title { font-size: $font-size-m; }
  }
}
