.skills {
  &.show-animation .skill {
    &::before { animation: load 2s .3s; }

    &.html::before { width: calc(85% - 10px); }
    &.css::before { width: calc(95% - 10px); }
    &.javascript::before { width: calc(70% - 10px); }
    &.jquery::before { width: calc(80% - 10px); }
    &.react::before { width: calc(25% - 10px); }
  }
}

.skill {
  margin-bottom: $space-xs;
  border: 1px solid #2f2f2f;
  border-radius: 5px;
  color: #000;
  
  &::before {
    content: attr(data-skill);
    display: inline-block;
    width: 0;
    padding: $space-xs 0 $space-xs $space-s;
    border-radius: inherit;
  }
  
  &.html::before { background-color: #20639b; }
  &.css::before { background-color: #2a9d8f; }
  &.javascript::before { background-color: #e9c46a; }
  &.jquery::before { background-color: #f4a261; }
  &.react::before { background-color: #e76f51; }
}

@keyframes load{
  from { width: 0%; }
}
