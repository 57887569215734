@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-down-right {
  0% {
    opacity: 0;
    transform: translate3d(-100px, -100px, 0)
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0)
  }
}