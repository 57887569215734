// Generic
@import 'generic/reset';
@import 'generic/colors';
@import 'generic/fonts';
@import 'generic/spaces';
@import 'generic/breakpoints';
@import 'generic/general';
@import 'generic/animations';

// Elements
@import 'elements/titles';
@import 'elements/paragraph';

// Components
@import 'components/nav';
@import 'components/skills';
@import 'components/works';

// Pages
@import 'pages/home';
@import 'pages/contact';
