.title {
  margin: 0 0 $space-xl;
  font: 300 2rem/1.2 'Oswald', sans-serif;

  @media only screen and (max-width: $max-breakpoint-s) {
    font-size: $font-size-xxl;

    &--upper:first-of-type { padding-top: $space-xxl; }
  }

  @media only screen and (max-width: $max-breakpoint-xs) {
    &--upper:first-of-type { max-width: calc(100% - 10rem); }
  }

  &--center { text-align: center; }
  
  &--upper { text-transform: uppercase; }

  &--welcome {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: calc(50% - 32rem);
    transform: translateY(-50%);
    margin: 0;
    font-size: 8rem;
    line-height: .7;
    color: #f5f5f5;
    animation: 3s cubic-bezier(.77, .2, .05, 1) fade;

    span {
      position: relative;
      top: -$space-m;
      display: inline-block;
      font-size: .5em;
      text-indent: 6rem;

      ~ span {
        position: static;
        font-size: 1em;
        text-indent: 3rem;
      }
    }

    .signature {
      width: 100%;
      font-family: 'Lato', sans-serif;
      font-size: 1.5rem;
      text-align: right;
    }

    @media only screen and (max-width: $max-breakpoint-xl) {
      left: calc(50% - 30rem);
      font-size: 5.75rem;

      span { top: -1.125rem; }
    }

    @media only screen and (max-width: $max-breakpoint-l) {
      left: 1.5rem;
      font-size: 3.5rem;
      line-height: .75;

      span { top: -.75rem; }

      .signature {
        margin-top: $space-l;
        font-size: 1.25rem;
        text-align: left;
        text-indent: unset;
      }
    }

    @media only screen and (max-width: $breakpoint-m) {
      top: $space-m;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;

      span,
      span ~ span { text-indent: unset; }
    }

    @media only screen and (max-width: $breakpoint-xs) {
      width: 100%;
      font-size: 2rem;

      span { top: -.35rem; }

      .signature {
        margin-top: $space-l;
        font-size: 1.125rem;
        text-align: center;
      }
    }
  }
}
