body {
  font: 300 16px 'Lato', sans-serif;
  color: #f5f5f5;
  background-color: #0f0f0f;
}

.has-page-shown {
  @media only screen and (max-width: $max-breakpoint-s) {
    .logo {
      width: 3rem;
      height: 3rem;
    }
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.logo {
  position: relative;
  top: 0;
  left: 0;
  z-index: 4;
  margin: 1.5rem 0 0 1rem;
  transition: top 1s, left 1s;

  @media only screen and (max-width: $breakpoint-l) {
    width: 4rem;
    height: 4rem;
    margin: 1rem 0 0 .5rem;
  }
}

.page {
  box-sizing: border-box;
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100%;
  font-size: $font-size-m;
  background-color: rgba(15, 15, 15, .95);
  overflow-y: auto;

  &--top-left,
  &--top-right {
    top: -100%;

    &.is-shown { top: 0; }
  }
  
  &--top-right,
  &--bottom-right {
    right: -100%;

    &.is-shown { right: 0; }
  }

  &--bottom-left,
  &--bottom-right {
    bottom: -100%;

    &.is-shown { bottom: 0; }
  }

  &--top-left,
  &--bottom-left {
    left: -100%;

    &.is-shown { left: 0; }
  }
  
  &--top-left { transition: top 1s, left 1s; }
  &--top-right { transition: top 1s, right 1s; }
  &--bottom-left { transition: bottom 1s, left 1s; }
  &--bottom-right { transition: bottom 1s, right 1s; }

  .title {
    padding: $space-l 0 0;
    margin: 0 auto $space-s;
  }

  &--bottom-right .title {
    position: static;

    &--upper { position: sticky; }
  }

  .paragraph {
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: $space-m;
    }
  }

  @media only screen and (max-width: $max-breakpoint-s) {
    &::before,
    &::after {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      width: 0;
      height: 0;
      border-top: 7rem solid #0f0f0f;
    }
    
    &::before {
      left: 0;
      border-right: 7.5rem solid transparent;
    }

    &::after {
      right: 0;
      border-left: 7.5rem solid transparent;
    }

    padding: 0 $space-m $space-m;
  }

  @media only screen and (max-width: $max-breakpoint-xs) {
    padding: 0 $space-s $space-s;

    .title { padding-top: $space-m; }
  
    .paragraph { font-size: $font-size-s; }
  }
}

.container {
  width: calc(100% - 16rem);
  margin: 0 auto;

  @media only screen and (min-width: $min-breakpoint-xl) {
    max-width: 64rem;
  }

  @media only screen and (max-width: $max-breakpoint-l) {
    width: calc(100% - 10rem);
  }

  @media only screen and (max-width: $max-breakpoint-s) {
    width: 100%;
  }
}

.close {
  position: fixed;
  z-index: 4;
  top: $space-xxl;
  right: $space-xxl;
  display: block;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  transform: translate(10rem, -10rem);
  transition: transform .3s;

  &.is-visible { transform: translate(0, 0); }

  @media (hover: hover) {
    &:hover,
    &:focus { transform: rotate(-180deg); }
  }
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: $space-s;
    height: 2rem;
    width: .125rem;
    background-color: #f5f5f5;
  }
  
  &::before { transform: rotate(45deg); }
  &::after { transform: rotate(-45deg); }

  @media only screen and (max-width: $max-breakpoint-l) {
    top: $space-m;
    right: $space-m;
  }
}
