.page--bottom-left {
  .title { margin-bottom: $space-huge; }

  .paragraph {
    span,
    img {
      display: inline-block;
      vertical-align: middle;
      margin: $space-xl;
    }
  
    img {
      opacity: 1;
      transition: opacity .3s;
      
      @media (hover: hover) {
        &:hover,
        &:focus { opacity: .75; }
      }
    }
  
  
    a { text-decoration: none; }
  
    span { font-size: 4.75rem; }
  }
}
