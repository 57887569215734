$breakpoint-xxs: 320px;
$breakpoint-xs: 480px;
$breakpoint-s: 640px;
$breakpoint-m: 768px;
$breakpoint-l: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-xxl: 1440px;

$max-breakpoint-xxs: 319px;
$max-breakpoint-xs: 479px;
$max-breakpoint-s: 639px;
$max-breakpoint-m: 767px;
$max-breakpoint-l: 1023px;
$max-breakpoint-xl: 1279px;
$max-breakpoint-xxl: 1439px;

$min-breakpoint-xxs: 321px;
$min-breakpoint-xs: 481px;
$min-breakpoint-s: 641px;
$min-breakpoint-m: 769px;
$min-breakpoint-l: 1025px;
$min-breakpoint-xl: 1281px;
$min-breakpoint-xxl: 1441px;
